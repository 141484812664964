import * as moment from 'moment';

import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { NzUploadComponent, NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Subject, takeUntil } from 'rxjs';

import { AuthState } from '../../action-state/states/auth.state';
import { Constants } from 'src/app/constants';
import { DateConvertPipe } from 'src/app/pipes/date-time.pipe';
import { HelperService } from 'src/app/services/helper.service';
import { IndexDbService } from '../../services/indexDb.service';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SharedService } from 'src/app/services/shared.service';
import { Store } from '@ngxs/store';
import { TeamState } from '../../action-state/states/team.state';
import { UserGalleryService } from '../../services/userGallery.service';
import { WasabiService } from '../../services/wasabi.service';

@Component({
  selector: 'app-gallery-preview',
  templateUrl: './gallery-preview.component.html',
  styleUrls: ['./gallery-preview.component.less']
})
export class GalleryPreviewComponent implements OnInit, OnChanges , OnDestroy {

  // onGoingHttpReq: any;
  private onGoingHttpReq: Subject<void> = new Subject();
  @Input() page = '';
  effect = 'scrollx';
  visible = false;
  filterVisible = false;
  UploadModalisVisible = false;
  galleryModalisVisible = false;
  openCreateAlbumModal = false;
  modalTitle = 'Create New Album';
  modalButton = 'Create Album'
  addAlbumTitle = 'Add to Album';
  albumForm: any;
  iconUrl = null;
  icon: NzUploadFile;
  deleteIcon = false;
  user = null;
  lastDate = null;
  addedImage: any = '';
  currentAlbumId = '';
  elementActive = false;
  isFavIcon = false;
  isFavIconForFavMedia = false;
  staticGallerySkeleton: any = [1, 2, 3, 4, 5, 6, 7, 8];
  scrollClass = "gallery_result media_grid_main";
  newAlbum = false;
  isAlbumListLoadning = false;
  staticConversationHash: any = Array(4).fill(0).map((x, i) => i);


  //upload component variable start
  @Input() isFeedPost = false;
  public loading = false;
  public isInstaThumbLoading = false;
  @Input() imageFile: any = [];
  public provider: any;
  @Input() images: any = [];
  public error = '';
  @Input() mediaData: any = [];
  @Input() maxLength = 10;
  @Input() type = 'image'; //image,gif,video, multi
  @Input() isForUpdate = true;
  @Output() deleteChange = new Subject();
  @Input() deletedPhotos: any = [];
  public accountProvider: string;
  @Input() allAccountProvider: any = [];
  @Input() tempPost = false;
  @Input() instagramType = '';
  selectedImage: any = '';
  selectedImageBase64: any = '';
  croppedImage: any = '';
  aspectRatio = 1;
  isVideoValid = true;
  @Input() cropTool = false;
  imagepreview: any;
  galleryFiles: any = [];
  @Input() galleryMediaData: any = [];
  selectedImageURL: any = '';
  galleryLoading = false;
  galleryCurrentPage = 1;
  filterFileType = { 'media': '', 'albums': '', 'fav': '', 'allImageAlbum': '' };
  sortFilterFileType = '';
  filterType: any = { 'media': 'DESC', 'albums': 'DESC', 'fav': 'DESC', 'allImageAlbum': 'DESC' };
  sortFilterType = 'DESC';
  tabType = 'media';
  selectedGalleryFile: any = '';
  selectedGalleryFileMeta: any = '';
  selectedTab: any = '-';
  cropImgLoaded = false;
  imageLoading = '../../assets/images/loading.gif';
  currentGalleryTab = 'library';
  isVideoUploaded = false;
  canSelectMediaFromGallery = true;
  oldType = 'image';
  @Input() initLoadGallery = true;
  @Input() deletedGalleryMedias: any = [];
  @Input() youtubeThumbnails: any = [];
  @Input() instaReelThumbnails: any = [];
  scrollLoading = false;
  percent = 0;
  totalProgress = 0;
  public timerInterval: any;

  @Input() isDirectUpload = false;
  isCropped = false;
  @Output() editImageChanges = new Subject();

  @Input() isEditImage = false;
  @Input() editImageIndex = 0;


  // upload component variable end
  @Input() isPosting = false;

  //.

  currentTab = 0;


  albumDate = null;
  galleryFilesFav = [];
  selectedMediaFromAlbum: any = '';
  selectedMediaFromIsFav: any = '';
  isVisible = false;
  supportedFileTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'video/mov', 'video/quicktime', 'video/mp4', 'video/m4v'];
  fileMetaDatas = [];
  fileTypes = [];
  mediaFiles = [];
  permissions = null;
  currentIndex = 0;
  subIndex = 0;
  currentUploading = [];
  galleryDate: any = moment();
  yesterDayDate: any;
  albumList: any = [];
  albumId: any = null
  filterRadio: any = null;
  addToAlbumVisible: any = false;
  albumDeletevisible = false;
  editAlbumId = 0;
  isDisabled = false;
  deleteImagevisible = false;
  deleteImageFromSelected: any;
  lastIndex: any;
  albumDeleteId = null;
  is_favorite = false;
  medialListFromAlbum: any = [];
  albumData = false;
  removeFileTypeFile = true;
  isDisable = false;
  noGalleryData = false;
  currentAlbumName: any = '';
  index: any
  postData: any = [];
  isLoading = false;
  loadingPostType = '';
  isView = false;

  httpSubscriptionGallery: any = null;
  httpSubscriptionAlbum: any = null;
  httpSubscriptionFav: any = null;


  //..
  addToAlbumTag = '';
  removeMediaFromAlbum = false;
  removeAlbumMedia: any = '';
  deleteTag = '';

  //Gallery mx image upload
  imageArray = [];
  isExitingLimit = false;
  isTotalCount = 0

  supportedThumbnailFileTypes = ['image/jpg', 'image/jpeg', 'image/png'];

  @ViewChild('userPhoto') userPhoto: NzUploadComponent;

  selectedSingleMedia = null;
  selectedMedias = [];
  removeAllFav = false;
  selectedAlbumIds = [];
  selectedGalleryFiles = [];
  selectedGalleryFileMetas = [];

  @Input() multi_images = [];
  @Output() imagesChanged =  new Subject();


  isValidAspectRatio = true;
  httpSubscriptionHashtag = null;
  hashtags = [];
  isShowHashTagGenerate = false;
  hashTagImg = null;
  selectedHashtags: string[] = [];
  isGalleryImageLoading = true;






  constructor(private indexDbService: IndexDbService,
    private userGalleryService: UserGalleryService,
    private messageService: NzMessageService,
    private modal: NzModalService,
    private store: Store,
    private wasabiService: WasabiService,
    private dateConvertPipe: DateConvertPipe,
    private formBuilder: FormBuilder,
    private modalService: NzModalService,
    public sharedService: SharedService,
    private notificationServices: NzNotificationService,) {}



  async ngOnInit(){
    this.modalTitle = 'Create Album'

    this.openGallery();
    this.getAlbumList();

    //this.getIsFavoriteGallery();


    this.permissions = this.store.selectSnapshot(TeamState.permissions);

    this.galleryDate = this.galleryDate.format('YYYY-MM-DD');
    this.yesterDayDate = moment().subtract(1, "days").format("YYYY-MM-DD");
    this.albumForm = this.formBuilder.group({
      album_name: [null, [Validators.required]],
    });


    if (this.allAccountProvider.includes(this.sharedService.instagramProvider)) {
      this.cropTool = true;
    }

    if (this.cropTool && !this.isDirectUpload) {
      this.selectedImageBase64 = await this.getBase64FromUrl(this.selectedImageURL);
    }

    if (this.isDirectUpload) {
      document.querySelector('.upload-modal-create-post').classList.add('display_none');
    }
    // if(this.tabType = 'media'){
    //   this.currentTab = 1;
    // }else if(this.tabType = 'albums'){
    //   this.currentTab = 2;
    // }else if(this.tabType = 'fav'){
    //   this.currentTab = 3;
    // }


    this.addToAlbumTag = 'Add to Album';
    this.deleteTag = 'Delete';


    if(this.type === 'multi'){
      this.multi_images = this.mediaData.concat(this.galleryMediaData);
    }
    console.log("instagramType : ", this.instagramType);
  }


  ngOnChanges(changes: SimpleChanges) {
    console.log("GalleryPreviewComponent ngOnChanges")
    console.log("GalleryPreviewComponent type",this.type)
    console.log("GalleryPreviewComponent images",this.images)
    console.log("GalleryPreviewComponent mediaData",this.mediaData)
    console.log("GalleryPreviewComponent galleryMediaData",this.galleryMediaData)

    if (changes) {
      console.log('GalleryPreviewComponent has changed:', changes);
      if(this.type === 'multi'){
        this.multi_images = this.mediaData.concat(this.galleryMediaData);
      }
    }
    console.log("GalleryPreviewComponent2 mediaData",this.mediaData)
    console.log("GalleryPreviewComponent2 galleryMediaData",this.galleryMediaData)
  }

  ngOnDestroy() {
    // cancel previous http call
    if (this.httpSubscriptionGallery !== null) {
      this.httpSubscriptionGallery.unsubscribe();
    }

    if (this.httpSubscriptionAlbum !== null) {
      this.httpSubscriptionAlbum.unsubscribe();
    }

    if (this.httpSubscriptionFav !== null) {
      this.httpSubscriptionFav.unsubscribe();
    }
    if (this.currentUploading.length > 0) {
      this.onGoingHttpReq.unsubscribe();
    }
    this.onGoingHttpReq.unsubscribe();
  }

  getFilterType() {
    return this.filterType[this.tabType];
  }

  getFilterMediaType() {
    return this.filterFileType[this.tabType];
  }

  setFilterType(value) {
    this.sortFilterType  = value;
  }
  setFilterMediaType(value) {
    this.sortFilterFileType = value;
  }


  galleryModal(): void {
    this.galleryModalisVisible = true;
  }

  galleryCloseModal(): void {

    this.subIndex = 0;
    this.lastIndex = 0;
    this.galleryModalisVisible = false;
    this.selectedGalleryFile = null;
    this.isView = false;
    if (this.selectedMediaFromIsFav != null) {
      this.getIsFavoriteGallery();
    }
    // this.resetSelection();
  }

  sortModal(): void {
    this.visible = true;
  }
  applyFilter(): void {
    this.visible = false;
  }

  FilterByModal(): void {
    this.filterVisible = true;
  }

  @ViewChild(NzCarouselComponent, { static: false }) myCarousel: NzCarouselComponent;


  filterDateChange(): void {
    this.visible = false;
    this.lastDate = null;
    this.scrollClass = "gallery_result media_grid_main";
    this.filterType[this.tabType] = this.sortFilterType;

    this.galleryLoading = true;
    this.galleryFiles = [];
    this.lastIndex = null;
    if (this.tabType == 'media') {
      this.openGallery();
    } else if (this.tabType == 'albums') {
      if (!this.currentAlbumId) {
        this.getAlbumList();
      }
    } else if (this.tabType == "allImageAlbum" && this.currentAlbumId) {
      this.allImageFromAlbum(this.currentAlbumId, this.currentAlbumName);
    }
    else {
      this.getIsFavoriteGallery();
    }

  }

  filterFileTypeChange(): void {
    this.filterVisible = false;
    this.filterFileType[this.tabType] = this.sortFilterFileType;
    this.galleryLoading = true;
    this.selectedImage = null;
    this.lastIndex = null;
    this.galleryFiles = [];
    if (this.tabType == 'media') {
      this.openGallery();
    } else if (this.tabType == 'albums') {
      if (!this.currentAlbumId) {
        this.getAlbumList();
      }
    } else if (this.tabType == "allImageAlbum" && this.currentAlbumId) {
      this.allImageFromAlbum(this.currentAlbumId, this.currentAlbumName);
    }
    else {
      this.getIsFavoriteGallery();
    }

  }
  close_filter_modal(): void {
    this.filterVisible = false;
  }
  close_sort_modal(): void {
    this.visible = false;
  }

  async openGallery() {
    // if(action != null && action === 'uploadImage'){
    //   this.currentTab = 0;
    // }
    this.tabType = 'media';
    this.selectedGalleryFile = null;
    this.error = null;
    this.galleryFiles = [];
    this.galleryLoading = true;

    // cancel previous http call
    if (this.httpSubscriptionGallery !== null) {
      this.httpSubscriptionGallery.unsubscribe();
    }
    this.httpSubscriptionGallery = await this.userGalleryService.getUserGallery(this.filterFileType.media, this.filterType.media).subscribe((res) => {
      this.galleryLoading = false;
      this.removeFileTypeFile = true;

      if (Object.keys(res.data.collection).length > 0) {
        var result = Object.entries(res.data.collection);
        this.lastDate = res.data.last_date;
        this.galleryFiles.push(result);

        // this.galleryFiles = [this.galleryFiles,...result];
      }
      // this.galleryFiles.length == 0 ? this.noGalleryData = true : this.noGalleryData = false;
      this.galleryFiles.forEach(ele => {
        if (ele.length != 0) {
          this.noGalleryData = false
        } else {
          this.noGalleryData = true;
        }
      })
    }, error => {

    });

  }

  onScroll() {
    this.scrollClass = "gallery_result media_grid_main scroll_gallery_main";
    if (this.scrollLoading) {
      return;
    }
    this.scrollLoading = true;
    if (this.lastDate != null) {
      this.userGalleryService.getUserGallery(this.filterFileType.media, this.filterType.media, this.lastDate).subscribe((res) => {

        console.log(res,"gallery res");


        console.log(res,"gallery res");
        this.removeFileTypeFile = true;
        this.scrollLoading = false;
        var result = Object.entries(res.data.collection);
        console.log(result,"Gallery result 2");


        this.lastDate = res.data.last_date;
        this.galleryFiles.push(result);
        console.log(this.galleryFiles,"this.galleryFiles");
      }, error => {

      });
    } else {
      this.scrollLoading = false;
    }
  }

  step(type: string) {
    const index = type == 'prev' ? this.subIndex - 1 : this.subIndex + 1;
    this.subIndex = index;
    this.isView = true;
    if (this.selectedMediaFromAlbum != null && this.selectedMediaFromAlbum.length != 0) {
      this.lastIndex = this.medialListFromAlbum.length;
      this.selectedMediaFromAlbum = this.medialListFromAlbum[this.subIndex];
      this.selectedMediaFromIsFav = null;
    }
    else if (this.selectedMediaFromIsFav != undefined && this.selectedMediaFromIsFav.length != 0) {
      this.lastIndex = this.galleryFilesFav.length;
      this.selectedMediaFromIsFav = this.galleryFilesFav[this.subIndex];
      this.selectedMediaFromAlbum = null;
    }
    else {
      var selectedGalleryData = this.galleryFiles;
      var media = null;
      let length = 0;
      selectedGalleryData.forEach((element, value) => {
        element.forEach((e: (string | any[])[], value: any) => {
          if (e[0] == this.albumDate) {
            media = e[1][index];
            length = e[1].length;
          }
        });
      });
      this.selectedGalleryFile = media;
      this.lastIndex = length;
      this.selectedMediaFromAlbum = null;
      this.selectedMediaFromIsFav = null;
    }
  }

  async selectImageFromGallery(media: any, albumDate: any, mainIndex: number, subIndex: number, length?: any, event:MouseEvent=null) {
    event?.stopPropagation();
    event.preventDefault();

    console.log("selectImageFromGallery media:",media)
    this.selectedSingleMedia = media._id;
    this.error = '';
    this.galleryModalisVisible = true;
    this.isFavIcon = true;
    this.isFavIconForFavMedia = false;
    this.selectedImageURL = null;
    this.currentIndex = mainIndex;
    this.subIndex = subIndex;
    this.albumDate = albumDate;
    this.selectedGalleryFile = media;

    this.addToAlbumTag = 'Add to Album';
    this.deleteTag = 'Delete';

    const fileMeta = JSON.parse(media.file_metadata);
    const filePath = fileMeta.media_file.split('/');
    this.selectedGalleryFileMeta = {
      ...fileMeta,
      file_name: filePath[filePath.length - 1],
      file_size: this.bytesToSize(fileMeta.file_size),
      duration: this.secondsToTime(fileMeta.duration),
    };
    console.log("selectImageFromGallery selectedGalleryFile:",this.selectedGalleryFile)
    console.log("selectImageFromGallery selectedGalleryFileMeta:",this.selectedGalleryFileMeta)


    const rand = '&rand=' + Math.random();
    //const galleryImageUrl = await this.indexDbService.getPresignedImageUrl(media.media_file) + rand;


    //const galleryImageUrl = await this.indexDbService.getPresignedImageUrl(media.media_file);
    //console.log("selectImageFromGallery galleryImageUrl : ", galleryImageUrl);

    let currentType = this.selectedGalleryFileMeta?.file_type;
    this.oldType = this.type;
    if (this.type != "video") {
      if (fileMeta.file_mime_type === 'image/gif') {
        this.type = 'gif';
      } else {
        if(this.images.length > 0 && currentType==='video'){
          this.type = 'multi';
        }else{
          this.type = this.selectedGalleryFileMeta?.file_type;
        }
      }
    }

    /*if (this.type === 'video') {
      this.maxLength = 1;
    } else {
      this.maxLength = 10;
    }*/

    this.canSelectMediaFromGallery = true;
    if (this.type === 'video' || this.isVideoUploaded) {
      /*if (this.images.length > 0) {
        this.error = 'You can upload Images/GIFs or a one video at a time if you want to upload video remove the other media file';
        this.canSelectMediaFromGallery = false;
        this.type = this.oldType;
        return;
      }
      this.type = 'video';
      this.maxLength = 1;*/

      if(this.images.length > 0 && currentType==='image'){
        this.type = 'multi';

      }

      this.images = [];
      this.galleryMediaData = [];
    }

    /*if (this.cropTool && fileMeta.file_type === 'image' && fileMeta.file_mime_type !== 'image/gif') {
      document.querySelector('.upload-modal-create-post')?.classList.add('display_none');
      this.selectedImageURL = await this.getBase64FromUrl(galleryImageUrl)
      this.canSelectMediaFromGallery = false;
    }*/

    this.selectedMediaFromAlbum = null;
    this.selectedMediaFromIsFav = null
    this.lastIndex = length;

    console.log("selectImageFromGallery type:",this.type)
  }


  async selectMediaFromGallery(media: any) {
    console.log("selectMediaFromGallery media:",media)

    this.error = '';
    this.galleryModalisVisible = true;
    this.isFavIcon = true;
    this.isFavIconForFavMedia = false;
    this.selectedImageURL = null;
    this.addToAlbumTag = 'Add to Album';
    this.deleteTag = 'Delete';

    //multiple files
    //this.selectedGalleryFiles = [...this.selectedGalleryFiles, media];
    if (media.is_checked) {
      this.selectedGalleryFiles = [...this.selectedGalleryFiles, media];
    }else{
      this.selectedGalleryFiles = this.selectedGalleryFiles.filter(item => item !== media);
    }

    const fileMeta = JSON.parse(media.file_metadata);
    const filePath = fileMeta.media_file.split('/');
    this.selectedGalleryFileMetas = [...this.selectedGalleryFileMetas,{
      ...fileMeta,
      file_name: filePath[filePath.length - 1],
      file_size: this.bytesToSize(fileMeta.file_size),
      duration: this.secondsToTime(fileMeta.duration),
    }];

    console.log("selectMediaFromGallery selectedGalleryFile:",this.selectedGalleryFiles)
    console.log("selectMediaFromGallery selectedGalleryFileMetas:",this.selectedGalleryFileMetas)

    this.canSelectMediaFromGallery = false;
    //const galleryImageUrl = await this.indexDbService.getPresignedImageUrl(media.media_file);

    //for fast load
    const galleryImageUrl =  this.wasabiService.getWasabiPresignedUrl(media.media_file);
    console.log("selectMediaFromGallery galleryImageUrl : ", galleryImageUrl);
    this.canSelectMediaFromGallery = true;

    let currentType = fileMeta?.file_type;
    this.oldType = this.type;

    console.log("selectMediaFromGallery currentType : ", currentType);
    console.log("selectMediaFromGallery oldType : ", this.oldType);

    if (this.type != "video") {
      if (fileMeta.file_mime_type === 'image/gif') {
        this.type = 'gif';
        console.log("selectMediaFromGallery gif : ", currentType);
      } else {
        //this.type = fileMeta?.file_type;
        if(this.images.length > 0 && currentType==='video'){
          this.type = 'multi';
          console.log("selectMediaFromGallery multi : ", currentType);
        }else{
          this.type = fileMeta?.file_type;
          console.log("selectMediaFromGallery !video else type : ", currentType);
        }
      }
    }else{

      console.log("selectMediaFromGallery images multi : ", this.images);
      //if(this.images.length > 0 && currentType==='image'){
      if(this.images.length > 0 || this.selectedGalleryFiles.length>0){
        this.type = 'multi';
        console.log("selectMediaFromGallery lase multi : ", currentType);
      }
    }

    /*if (this.type === 'video') {
      this.maxLength = 1;
    } else {
      this.maxLength = 10;
    }*/


    if (this.type === 'video' || this.isVideoUploaded) {
      /*if (this.images.length > 0) {
        this.error = 'You can upload Images/GIFs or a one video at a time if you want to upload video remove the other media file';
        this.canSelectMediaFromGallery = false;
        this.type = this.oldType;
        return;
      }
      this.type = 'video';
      this.maxLength = 1;*/

      this.images = [];
      this.galleryMediaData = [];
    }

    /*if (this.cropTool && fileMeta.file_type === 'image' && fileMeta.file_mime_type !== 'image/gif') {
      document.querySelector('.upload-modal-create-post')?.classList.add('display_none');
      this.selectedImageURL = await this.getBase64FromUrl(galleryImageUrl)
      this.canSelectMediaFromGallery = false;
    }*/

    this.selectedMediaFromAlbum = null;
    this.selectedMediaFromIsFav = null

    console.log("selectMediaFromGallery type : ", this.type);
  }


  getBase64FromUrl = async (url) => {
    var base64data
    const data = await fetch(url
      , {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Origin: window.location.origin,
        }
      });
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        base64data = reader.result;
        resolve(base64data);
      };
    })
  }

  getIsFavoriteGallery(action = null) {

    if (action !== null && action !== 'deleteFavImage') {
      this.currentTab = 2;
    }
    this.tabType = 'fav';
    // this.currentTab = 2;

    this.galleryLoading = true;
    this.galleryCurrentPage = 1;
    this.selectedGalleryFile = null;
    this.error = null;
    this.galleryFilesFav = [];
    // cancel previous http call
    if (this.httpSubscriptionFav !== null) {
      this.httpSubscriptionFav.unsubscribe();
    }
    this.httpSubscriptionFav = this.userGalleryService.getIsFavoriteGallery(this.galleryCurrentPage, this.filterFileType.fav, this.filterType.fav).subscribe((res) => {
      this.removeFileTypeFile = true;
      this.galleryLoading = false;
      if (res.data.data?.length > 0) {
        this.galleryFilesFav = res.data.data;
      }
      this.galleryFilesFav.length == 0 ? this.noGalleryData = true : this.noGalleryData = false;

    });
  }
  onScrollIsFav() {
    if (this.scrollLoading) {
      return;
    }
    this.scrollLoading = true;
    this.galleryCurrentPage++;
    this.userGalleryService.getIsFavoriteGallery(this.galleryCurrentPage, this.filterFileType.fav, this.filterType).subscribe((res) => {
      this.scrollLoading = false;
      if (res.data.data?.length > 0) {
        const result = res.data.data;
        this.galleryFilesFav.push(...result);
      }
    });
  }

  bytesToSize(bytes: number) {
    if (bytes === 0) { return '0 Bytes'; }
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  secondsToTime(seconds: number) {
    if (seconds) {
      var date = new Date(0);
      date.setSeconds(seconds);
      var timeString = date.toISOString().substr(11, 8);
      return timeString;
    } else {
      return '';
    }
  }

  showUploadModal(): void {
    this.UploadModalisVisible = true;
  }


  async handleCancel() {
    this.UploadModalisVisible = false;
    if (this.images != undefined && this.images.length != 0 && !this.loading) {
      this.images.forEach(item => {
        let image = {
          images: item,
        };
        this.deleteWasabiMedia(image);
        this.currentUploading = [];
        this.images = [];
        this.mediaFiles = [];
      })
    } else {
      if (this.loading && this.currentUploading.length > 0) {
        this.onGoingHttpReq.next();
        this.currentUploading = [];
        this.images = [];
        this.mediaFiles = [];
        this.UploadModalisVisible = false;
        return false;
      }
    }

  }

  async deleteWasabiMedia(postData) {
    await this.userGalleryService.removeMediaOnCancel(postData).subscribe(res => {

    });
  }

  async handleOk() {

    const postData = {
      file_metadata: this.fileMetaDatas,
      file_type: this.fileTypes,
      media_file: this.mediaFiles,
      album_id: this.albumId,
    };
    this.isLoading = true;
    this.loadingPostType = 'addGallery';

    if (this.mediaFiles.length > 0 && !this.loading) {

      this.loading = false;
      await this.userGalleryService.uploadMedia(postData).subscribe(res => {
        let body = JSON.parse(JSON.stringify(res)).body;
        if (body !== undefined && body.code === 200) {

          this.loading = false;
          this.isLoading = false;
          this.images = [];
          this.fileMetaDatas = [];
          this.fileTypes = [];
          this.mediaFiles = [];
          this.currentUploading = [];
          this.galleryFiles = [];
          this.UploadModalisVisible = false;


          if(this.albumId){
            this.allImageFromAlbum(this.albumId);
          }else{
            this.openGallery();
            this.tabChanged(0);
          }

          // this.tabType = "media";
        }
        if (body !== undefined && body.code === 500 && body.toast === true) {
          this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, body.message);
        }
      }, error => {
        this.isLoading = false;
        this.loading = false;
      });

    } else {

      if (this.loading) {
        this.sharedService.displayNotification(this.sharedService.messageTypeInfo, 'Media uploading', 'Media is uploading. Please wait...');
        this.UploadModalisVisible = true;
        this.isLoading = false;
        return false;
      } else {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Media uploading', 'You not Selected any media for Upload.');
        this.UploadModalisVisible = true;
        this.isLoading = false;
        return false;
      }

    }
  }


  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]): boolean => {
    this.isTotalCount = _fileList.length

    if (this.images.length >= this.maxLength) {
      this.sharedService.displayNotification(this.sharedService.messageTypeInfo, 'Media Uploading', 'You can not upload more than ' + this.maxLength + ' Media Files!');
      return false;
    }

    this.error = null;
    if (this.supportedFileTypes.includes(file.type)) {

      const size = file.size;

      const fileMimeType = file.type;
      const type = file.type.split('/')[0];
      let ext = file.name.substring(file.name.lastIndexOf(".") + 1);

      let folder = 'gallery/user-' + this.store.selectSnapshot(AuthState.user).id + '/';
      let fileName = moment().unix() + '_' + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5) + '.' + ext;
      let fileMeta = { file_size: size, file_type: type, file_mime_type: fileMimeType, media_file: folder + fileName };
      this.loading = true;

      if (!this.fileExistsInUploading(fileName)) {
        this.currentUploading.push({ file_name: fileName, percent: 0, loading: true });
      }
      var index = 0;
      var currentPercent = 0;
      var intervalArray = [];
      let data = false;


      this.wasabiService.uploadMediaHTTP(file, folder, fileName).pipe(
        takeUntil(this.onGoingHttpReq)
      ).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:

            index = this.currentUploading.findIndex(x => x.file_name === fileName);
            if (index >= 0) {

              intervalArray[index] = setInterval(() => {
                // get realtime index
                index = this.currentUploading.findIndex(x => x.file_name === fileName);
                if (currentPercent !== 99) {
                  currentPercent++;
                  if (this.currentUploading[index]) {
                    this.currentUploading[index].percent = currentPercent;
                  }
                }
              }, 1000);
            }
            break;
          case HttpEventType.DownloadProgress:
            if (this.currentUploading[index]) {
              this.currentUploading[index].percent = 100;
              clearInterval(intervalArray[index]);
            }
            break;
          case HttpEventType.Response:
            if (this.currentUploading[index]) {
              this.currentUploading[index].percent = 100;
            }

            // setTimeout to display media is uploaded
            setTimeout(() => {
              data = true;
              if (data) {
                let media_file = folder + fileName;
                this.images.push({
                  media_file: media_file,
                  file_type: type,
                });

                this.fileMetaDatas.push(JSON.stringify(fileMeta));
                this.fileTypes.push(type);
                this.mediaFiles.push(media_file);

                // delete from uploading list
                index = this.currentUploading.findIndex(x => x.file_name === fileName);
                this.currentUploading[index].loading = false;
                this.currentUploading.splice(index, 1);

                if (this.currentUploading.length === 0) {
                  this.loading = false;
                  for (var i = 1; i < this.images.length; i++) {
                    clearInterval(intervalArray[i]);
                  }
                }
              }
            }, 1000);
        }
      }, error => {

      });
      return true;

    } else {
      this.sharedService.displayNotification(this.sharedService.messageTypeInfo, 'Media Uploading', 'We can\'t quite use that type of file. Could you try one of the following instead: JPG, JPEG, PNG, GIF, MOV, MP4, M4V?');
    }
    return false; // key to manual upload
  }

  deleteImage(index: number, mediaFile: any): void {

    if (this.page != 'create_post') {

      const postData = {
        images: [this.images[index]],
      };

      this.images.splice(index, 1);
      this.fileMetaDatas.splice(index, 1);
      this.fileTypes.splice(index, 1);
      this.mediaFiles.splice(index, 1);
      // this.userGalleryService.removeMediaOnCancel(postData).subscribe(res => { });
      this.deletedPhotos.push(mediaFile);
      this.deleteChange.next({
        type: this.type,
        maxLength: this.maxLength,
        deletedPhotos: this.deletedPhotos,
        mediaData: this.mediaData,
        galleryMediaData: this.galleryMediaData,
        deletedGalleryMedias: this.deletedGalleryMedias
      });
    } else {

      this.error = '';
      let isGalleryMedia = false;
      this.galleryMediaData.filter((item, itemIndex) => {
        if (item.media_file === mediaFile) {
          this.galleryMediaData.splice(itemIndex, 1);
          isGalleryMedia = true;
          this.deletedGalleryMedias.push(mediaFile);
        }
      });

      if (!isGalleryMedia) {
        this.mediaData.filter((item, itemIndex) => {
          if (item.media_file === mediaFile) {
            this.mediaData.splice(itemIndex, 1);
          }
        });

        this.imageFile.splice(index, 1);
      }

      // if youtube video is selected
      if (this.allAccountProvider.includes(this.sharedService.youtubeProvider)) {
        this.youtubeThumbnails = [];
      }

      // if insta video is selected
      if (this.allAccountProvider.includes(this.sharedService.instagramProvider)) {
        this.instaReelThumbnails = [];
      }

      this.deletedPhotos.push(mediaFile);
      this.deleteChange.next({
        type: this.type, maxLength: this.maxLength, deletedPhotos: this.deletedPhotos, mediaData: this.mediaData,
        galleryMediaData: this.galleryMediaData, deletedGalleryMedias: this.deletedGalleryMedias, youtubeThumbnails: this.youtubeThumbnails
      });
      this.images.splice(index, 1);

      if (this.images.length === 0) {
        this.type = 'image';
        this.maxLength = 10;
        this.imageFile = [];
        this.deleteChange.next({
          type: this.type,
          maxLength: this.maxLength,
          deletedPhotos: this.deletedPhotos,
          mediaData: this.mediaData,
          galleryMediaData: this.galleryMediaData,
          deletedGalleryMedias: this.deletedGalleryMedias,
          youtubeThumbnails: this.youtubeThumbnails,
          instaReelThumbnails: this.instaReelThumbnails,
        });
      }

      if(this.type === 'multi'){
        this.multi_images = this.mediaData.concat(this.galleryMediaData);
      }

    }
  }

  fileExistsInUploading(fileName: string): boolean {
    return this.currentUploading.some(el => {
      return el.file_name === fileName;
    });
  }

  returnZero() {
    return 0;
  }

  openCreateAlbum() {
    this.modalTitle = "Create New Album";
    this.modalButton = 'Create Album';
    this.openCreateAlbumModal = true;
  }

  closeAlbumModal() {
    this.editAlbumId = 0;
    this.albumForm.reset();
    this.albumForm.patchValue({
      album_name: null,
    });
    this.modalButton = 'Create Album'
    this.iconUrl = '';
    this.openCreateAlbumModal = false;
    this.newAlbum = false;
    this.addToAlbumVisible = false;
  }


  saveAlbum() {
    for (const i in this.albumForm.controls) {
      this.albumForm.controls[i].markAsDirty();
      this.albumForm.controls[i].updateValueAndValidity();
    }

    if (this.albumForm.valid) {
      const value = this.albumForm.value;
      const postValue: any = HelperService.createFormData(value);

      if (this.editAlbumId != 0) {
        postValue.append('_id', this.editAlbumId);

      }

      this.isLoading = true;
      this.loadingPostType = 'albumChange';

      this.userGalleryService.createAlbum(postValue).subscribe((res) => {
        console.log("saveAlbum res:",res);
        if (res?.code == 200) {
          this.isLoading = false;
          this.albumForm.reset();
          this.getAlbumList();

          this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, 'Success', res?.message);
          this.openCreateAlbumModal = false;
          this.newAlbum = false;
        } else {
          this.isLoading = false;
          this.albumForm.reset();
          this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res?.message);
        }
      }, error => {
        this.isLoading = false;
        this.albumForm.reset();
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, Constants.ERROR_MSG);
        this.openCreateAlbumModal = false;
      });
    }
    this.editAlbumId = 0;
  }

  showAlbumModal(item: Object) {
    if (Object.keys(item).length > 0) {
      this.modalTitle = 'Edit Album';
      this.modalButton = 'Apply Changes'
      this.editAlbumId = item['_id'];
      this.albumForm.patchValue({
        album_name: item['album_name']

      });
      this.openCreateAlbumModal = true;
    } else {
      this.modalButton = 'Create Album'
      this.openCreateAlbumModal = true;
    }
  }

  getAlbumList(action = null) {

    if (action !== null && action !== 'deleteAlbumImage') {
      this.currentTab = 1;
    }

    this.tabType = 'albums';
    this.albumData = false;
    this.removeFileTypeFile = false;
    this.selectedGalleryFile = null;

    // cancel previous http call
    if (this.httpSubscriptionAlbum !== null) {
      this.httpSubscriptionAlbum.unsubscribe();
    }

    /*this.httpSubscriptionAlbum = this.userGalleryService.getAlbumList(this.filterType.albums).subscribe((res) => {
      if (res.code == 200) {
        this.albumList = res.data;
        this.albumId = res.data[0]?._id;
      }
    });*/

    this.isAlbumListLoadning = true;
    this.httpSubscriptionAlbum = this.userGalleryService.getAlbums(this.filterType.albums).subscribe((res) => {
      if (res.code == 200) {
        this.albumList = res.data;
        this.isAlbumListLoadning = false;
        /*if(this.currentTab===1) {
          this.albumId = res.data[0]?._id;
        }*/
      }
    });

  }


  addToAlbumSubmit(event: any) {

    if (this.albumList.length == 0) {
      this.sharedService.displayNotification(this.sharedService.messageTypeInfo, this.sharedService.defaultMessageError, 'Please Create New Album.');
      return false;
    }

    //var postValue = {};

    /*if (this.selectedGalleryFile != null) {
      postValue = { 'selected_image': this.selectedGalleryFile?._id, 'album_id': this.albumId }
    } else if (this.selectedMediaFromIsFav != null) {
      postValue = { 'selected_image': this.selectedMediaFromIsFav?._id, 'album_id': this.albumId }
    } else if (this.selectedMediaFromAlbum != null) {
      postValue = { 'selected_image': this.selectedMediaFromAlbum._id, 'album_id': this.albumId }
    }*/

    let selectedMediaIds = this.selectedMedias;
    if(this.selectedSingleMedia){
      selectedMediaIds = [this.selectedSingleMedia];
    }

    if(selectedMediaIds.length === 0){
      this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, "Please select at least one media");
      return;
    }

    var postValue = {
      'selected_image': selectedMediaIds,
      'album_id': this.selectedAlbumIds
    }

    this.isLoading = true;
    this.loadingPostType = 'addToAlbum';
    this.userGalleryService.addMediaToAlbum(postValue).subscribe(res => {
      this.isLoading = false;
      if (res?.code == 200) {
        this.addedImage = res.data[0];
        this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res?.message);
        this.isLoading = false;
        this.addToAlbumVisible = false;
        if(!this.selectedSingleMedia){
          this.resetSelection();
          this.getAlbumList();
        }
      } else {
        this.isLoading = false;
        this.addToAlbumVisible = true;
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res?.message);
      }

      /*if(res.code == 502){
        this.isLoading = false;
        this.addToAlbumVisible = true;
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res?.message);
      }*/

    })
  }


  deleteAlbumVisible(album_id: any) {
    this.albumDeletevisible = true
    this.albumDeleteId = album_id;
  }

  deleteAlbumModalClose() {
    this.albumDeletevisible = false
  }

  deleteAlbum(id: any) {
    this.isLoading = true;
    this.loadingPostType = 'deleteAlbum';
    this.userGalleryService.deleteAlbum(id).subscribe(res => {

      this.isLoading = false;
      this.openGallery();
      this.getAlbumList();
      if (res.code === 200) {
        this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
        this.galleryFiles = [];
        this.albumDeletevisible = false;
      } else {
        this.isLoading = false;
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
      }

    });

  }


  deleteImageSelected(id: any) {

    if (this.selectedGalleryFile != null) {
      var selectedImageForDelete = ([this.selectedGalleryFile?.media_file])
    }else if (this.selectedMediaFromAlbum != null) {
      selectedImageForDelete = ([this.selectedMediaFromAlbum?.media_file])
    }else if (this.selectedMediaFromIsFav != null) {
      selectedImageForDelete = ([this.selectedMediaFromIsFav?.media_file])
    }

    //from hover
    if(id){
      selectedImageForDelete = [id];
    }

    if(this.selectedMedias.length>0){
      selectedImageForDelete = this.selectedMedias
    }

    this.isLoading = true;
    this.loadingPostType = 'deleteMedia';
    this.userGalleryService.deleteMedia(selectedImageForDelete).subscribe(res => {
      this.isLoading = false;
      if (res?.code === 200) {
        if (res?.toast === true) {
          this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
          this.galleryFiles = [];
          this.deleteImagevisible = false;
          this.galleryModalisVisible = false;
          this.selectedImage = null;
        }

        /*this.openGallery();
        this.getIsFavoriteGallery('deleteFavImage');
        this.getAlbumList('deleteAlbumImage');
        this.selectedImage = null;*/

        this.resetSelection();
      }
      if (res?.code === 500 && res?.toast === true) {
        this.isLoading = false;
        this.isDisable = true;
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
      }
    }, error => {
      this.isDisable = false;
    });
  }

  addIsFavorite(selected_image_id: any) {
    var flag = false;
    if (this.selectedGalleryFile?.is_favorite !== "undefined" && this.selectedGalleryFile?.is_favorite == true) {
      flag = false;
    } else if (this.selectedMediaFromIsFav?.is_favorite !== "undefined" && this.selectedMediaFromIsFav?.is_favorite == true) {
      flag = false;
    } else if (this.selectedMediaFromAlbum?.is_favorite !== "undefined" && this.selectedMediaFromAlbum?.is_favorite == true) {
      flag = false;
    } else {
      flag = true;
    }

    if (this.selectedGalleryFile != null) {
      this.selectedGalleryFile.is_favorite = flag;
    } else if (this.selectedMediaFromAlbum != null && this.selectedGalleryFile == null) {
      this.selectedMediaFromAlbum.is_favorite = flag;
    } else {
      this.selectedMediaFromIsFav.is_favorite = flag;
    }

    var postValue: any = {
      'selected_image' : selected_image_id,
      'is_favorite': flag
    };

    this.userGalleryService.isFavorite(postValue).subscribe(res => {
      this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res?.message);
    });

  }



  allImageFromAlbum(album_id: string, name = null) {
    this.tabType = 'allImageAlbum';
    this.elementActive = true;
    this.galleryLoading = true;
    this.currentAlbumId = album_id
    this.albumId = album_id;
    this.subIndex = 0;
    this.galleryCurrentPage = 1;
    this.currentAlbumName = name;

    this.medialListFromAlbum = [];
    var postValue = { 'album_id': album_id, 'order_by': this.filterType.allImageAlbum, 'file_type': this.filterFileType.allImageAlbum, 'page': this.galleryCurrentPage };
    this.albumData = true;
    this.userGalleryService.allImageFromAlbum(postValue).subscribe((res: any) => {
      this.removeFileTypeFile = true;
      this.galleryLoading = false;
      this.elementActive = false;
      if (res.code === 200) {
        if (res.data.data?.length > 0) {
          this.medialListFromAlbum = res.data.data;
        }
        console.log(this.medialListFromAlbum);
        this.medialListFromAlbum.length == 0 ? this.noGalleryData = true : this.noGalleryData = false;
      }
    })
  }

  onScrollAllImageFromAlbum() {
    this.tabType = 'allImageAlbum';
    if (this.scrollLoading) {
      return;
    }
    this.scrollLoading = true;
    this.galleryCurrentPage++;
    var postValue = {
      'album_id': this.albumId,
      'order_by': this.filterType.allImageAlbum,
      'file_type': this.filterFileType.allImageAlbum,
      'page': this.galleryCurrentPage
    };
    this.userGalleryService.allImageFromAlbum(postValue).subscribe((res) => {
      this.scrollLoading = false;
      if (res.data.data?.length > 0) {
        const result = res.data.data;
        this.medialListFromAlbum.push(...result);
      }
    });
  }

  goBack() {
    this.currentAlbumId = '';
    this.medialListFromAlbum = [];
    this.albumData = false;
    this.scrollLoading = false;
    this.galleryLoading = false;
    this.removeFileTypeFile = false;
    this.filterFileType[this.tabType] = '';
    this.getAlbumList();
  }

  async selectImageFromAlbum(media: any, length: any,event:MouseEvent=null) {
    event?.stopPropagation();
    event?.preventDefault();

    this.selectedSingleMedia = media._id;

    this.isFavIcon = true;
    this.isFavIconForFavMedia = false;
    this.galleryModalisVisible = true;
    this.selectedMediaFromAlbum = media;
    this.selectedMediaFromIsFav = null;
    this.selectedGalleryFile = null;

    this.addToAlbumTag = 'Move to other album';
    this.deleteTag = 'Permanent Delete';


    this.subIndex = 0;
    this.medialListFromAlbum.forEach((element: { _id: any; }, index: number) => {
      if (element._id == media._id) {
        this.subIndex = index;
      }
    });
    this.lastIndex = this.medialListFromAlbum.length;

    if (this.page == 'create_post') {
      this.selectedGalleryFile = media;
      const rand = '&rand=' + Math.random();
      //const galleryImageUrl = await this.indexDbService.getPresignedImageUrl(media.media_file) + rand;
      const galleryImageUrl = await this.indexDbService.getPresignedImageUrl(media.media_file);
      const fileMeta = JSON.parse(media.file_metadata);
      const filePath = fileMeta.media_file.split('/');
      this.selectedGalleryFileMeta = {
        ...fileMeta,
        file_name: filePath[filePath.length - 1],
        file_size: this.bytesToSize(fileMeta.file_size),
        duration: this.secondsToTime(fileMeta.duration),
      };

      this.oldType = this.type;
      if (fileMeta.file_mime_type === 'image/gif') {
        this.type = 'gif';
      } else {
        this.type = this.selectedGalleryFileMeta?.file_type;
      }

      if (this.type === 'video') {
        this.maxLength = 1;
      } else {
        this.maxLength = 10;
      }
      this.canSelectMediaFromGallery = true;

      if (this.type === 'video' || this.isVideoUploaded) {

        if (this.images.length > 0) {
          this.error = 'You can upload Images/GIFs or a one video at a time if you want to upload video remove the other media file';
          this.canSelectMediaFromGallery = false;
          this.type = this.oldType;
          return;
        }
        this.type = 'video';
        this.maxLength = 1;
        this.images = [];
        this.galleryMediaData = [];
      }

      /*if (this.cropTool && fileMeta.file_type === 'image' && fileMeta.file_mime_type !== 'image/gif') {
        document.querySelector('.upload-modal-create-post')?.classList.add('display_none');
        this.selectedImageURL = await this.getBase64FromUrl(galleryImageUrl)
        this.canSelectMediaFromGallery = false;
      }*/

      this.selectedMediaFromAlbum = null;
      this.selectedMediaFromIsFav = null

    }
  }

  async selectImageFromIsFav(media: any,event:MouseEvent=null) {
    event?.stopPropagation();

    this.selectedSingleMedia = media._id;
    this.isFavIcon = false;
    this.isFavIconForFavMedia = true;
    this.galleryModalisVisible = true;
    this.selectedMediaFromIsFav = media;
    this.selectedMediaFromAlbum = null;
    this.selectedGalleryFile = null;

    this.addToAlbumTag = 'Add to Album';
    this.deleteTag = 'Delete';

    this.subIndex = 0;
    this.galleryFilesFav.forEach((element, index) => {

      if (element._id == media._id) {
        this.subIndex = index;
      }
    })
    this.lastIndex = this.galleryFilesFav.length;
    if (this.page == 'create_post') {

      this.selectedGalleryFile = media;
      const rand = '&rand=' + Math.random();
      //const galleryImageUrl = await this.indexDbService.getPresignedImageUrl(media.media_file) + rand;
      const galleryImageUrl = await this.indexDbService.getPresignedImageUrl(media.media_file);
      const fileMeta = JSON.parse(media.file_metadata);
      const filePath = fileMeta.media_file.split('/');
      this.selectedGalleryFileMeta = {
        ...fileMeta,
        file_name: filePath[filePath.length - 1],
        file_size: this.bytesToSize(fileMeta.file_size),
        duration: this.secondsToTime(fileMeta.duration),
      };

      this.oldType = this.type;
      if (fileMeta.file_mime_type === 'image/gif') {
        this.type = 'gif';
      } else {
        this.type = this.selectedGalleryFileMeta?.file_type;
      }

      if (this.type === 'video') {
        this.maxLength = 1;
      } else {
        this.maxLength = 10;
      }
      this.canSelectMediaFromGallery = true;

      if (this.type === 'video' || this.isVideoUploaded) {

        if (this.images.length > 0) {
          this.error = 'You can upload Images/GIFs or a one video at a time if you want to upload video remove the other media file';
          this.canSelectMediaFromGallery = false;
          this.type = this.oldType;
          return;
        }
        this.type = 'video';
        this.maxLength = 1;
        this.images = [];
        this.galleryMediaData = [];
      }

      /*if (this.cropTool && fileMeta.file_type === 'image' && fileMeta.file_mime_type !== 'image/gif') {
        document.querySelector('.upload-modal-create-post')?.classList.add('display_none');
        this.selectedImageURL = await this.getBase64FromUrl(galleryImageUrl)
        this.canSelectMediaFromGallery = false;
      }*/

      this.selectedMediaFromAlbum = null;
      this.selectedMediaFromIsFav = null
    }

  }

  cancelWithoutUpload() {
    this.wasabiService.cancelUpload();
    this.loading = false;
  }

  imageLoadedForCrop(image: LoadedImage) {
    console.log("imageLoadedForCrop image",image);
    this.cropImgLoaded = true;
  }

  changeAspectRatio(ratio): any {
    this.aspectRatio = ratio;
  }

  cancelCrop(): void {
    document.querySelector('.upload-modal-create-post')?.classList.remove('display_none');
    this.croppedImage = null;
    this.selectedImage = null;
    this.imagepreview = null;
    this.selectedImageURL = null;
    this.cropImgLoaded = false;
    this.selectedGalleryFile = null;
  }

  cancel() {
    for (let index = 0; index < this.modalService.openModals.length; index++) {
      this.modalService.openModals[index].close();
      this.modalService.openModals[index].destroy();
    }
  }

  base64ToFile(base64Image: string): Blob {
    const split = base64Image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }
  async storeFile(file): Promise<void> {
    console.log("storeFile file",file);

    const size = file.size;
    const fileMimeType = file.type;
    const type = file.type.split('/')[0];

    let folder = '';
    if (this.tempPost) {
      folder = 'tempPost/user-' + this.store.selectSnapshot(AuthState.user).id + '/';
    } else {
      folder = 'posts/user-' + this.store.selectSnapshot(AuthState.user).id + '/';
    }

    /*console.log("storeFile fileMimeType",fileMimeType);
    console.log("storeFile type",type);
    console.log("storeFile this.type",this.type);*/

    let fileName = '';
    //if (this.type === 'image') {
    if (type === 'image') {
      fileName = moment().unix() + '_' + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5) + '.jpg';
    } else {
      fileName = moment().unix() + '_' + file.name;
    }


    console.log("storeFile fileName",fileName);
    fileName = fileName.split(' ').join('_');
    const fileMeta = { file_size: size, file_type: type, file_mime_type: fileMimeType, media_file: folder + fileName };

    let data = false;
    this.wasabiService.uploadMediaHTTP(file, folder, fileName).subscribe((event: HttpEvent<any>) => {

      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.timerInterval = setInterval(() => {
            if (this.percent !== 99) {
              this.percent++;
            }
          }, 1000);
          break;
        case HttpEventType.DownloadProgress:
          this.percent = 100;
          clearInterval(this.timerInterval);
          break;
        case HttpEventType.Response:
          clearInterval(this.timerInterval);
          this.percent = 100;
          // setTimeout to display media is uploaded
          setTimeout(() => {
            data = true;
            this.percent = 0;
            this.loading = false;
            if (data) {
              this.imageFile.push(file);
              this.images.push(folder + fileName);
              this.mediaData.push(fileMeta);
              if(this.type==="multi"){
                this.multi_images.push(fileMeta);
              }
            } else {
              this.isVideoValid = false;
            }
          }, 1000);
      }
    }, error => {

    });
  }

  editImage(index, img: string): void {
    this.editImageChanges.next({ index: index });
  }

  imageCropped(event: ImageCroppedEvent): void {
    //console.log("imageCropped event",event);
    this.isValidAspectRatio = true;

    let calculatedAspectRatio: number = event.width / event.height;
    calculatedAspectRatio = parseFloat(calculatedAspectRatio.toFixed(2));
    //console.log("imageCropped aspectRatio",this.aspectRatio);
    //console.log("imageCropped calculatedAspectRatio",calculatedAspectRatio);

    /*if (this.aspectRatio === 1.91) {
      if (this.aspectRatio !== calculatedAspectRatio) {
        this.isValidAspectRatio = false;
      }
    }*/

    if (this.aspectRatio !== calculatedAspectRatio) {
      this.isValidAspectRatio = false;
    }

    this.croppedImage = event.base64;
  }

  cropImageManually() {
    document.querySelector('.upload-modal-create-post')?.classList.remove('display_none');
    const file = this.base64ToFile(this.imagepreview);
    this.uploadImage(file, true);
    this.croppedImage = null;
    this.selectedImage = null;
    this.imagepreview = null;
    this.selectedImageURL = null;
    this.selectedGalleryFile = null;
  }

  uploadImage(file, uploadFile = false): boolean {
    if (uploadFile) {
      if (file.size > (5 * 1024 * 1024) || file.size < 2 * 1024) {
        this.loading = false;
        this.error = 'Image size should be between 2KB and 5MB';
        return false;
      }
    }
    if (uploadFile) {
      this.loading = true;
      this.storeFile(file);
      return true;
    }
    else {
      return true;
    }
  }

  cropImage(): void {

    if (this.isEditImage) {
      this.deleteImage(this.editImageIndex, this.images[this.editImageIndex]);
    }
    document.querySelector('.upload-modal-create-post')?.classList.remove('display_none');
    const file = this.base64ToFile(this.croppedImage);
    console.log("cropImage file",file)
    this.uploadImage(file, true);
    this.croppedImage = null;
    this.selectedImage = null;
    this.imagepreview = null;
    this.selectedImageURL = null;
    this.isCropped = true;
  }

  beforeUploadThumbnail = (file: NzUploadFile, _fileList: NzUploadFile[]): boolean => {

    const fileExt: any = ['image/pjp', 'image/jpg', 'image/pjpeg', 'image/jpeg', 'image/jfif', 'image/png'];
    if (fileExt.includes(file.type)) {
      this.loading = true;
      this.storeThumbnailFile(file);
      return false;
    } else {
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Media Uploading', 'This File Extension is not supported');
      return false;
    }
  }

  beforeUploadInstagramReelThumbnail = (file: NzUploadFile, _fileList: NzUploadFile[]): boolean => {

    const fileExt: any = ['image/jpeg', 'image/png'];
    if (fileExt.includes(file.type)) {
      this.isInstaThumbLoading = true;
      this.storeThumbnailFile(file,this.sharedService.instagramProvider);
      return false;
    } else {
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Media Uploading', 'This File Extension is not supported');
      return false;
    }
  }

  async storeThumbnailFile(file,type=''): Promise<void> {
    let folder = '';
    folder = 'temp/user-' + this.store.selectSnapshot(AuthState.user).id + '/';
    let fileName = moment().unix() + '_' + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5) + '.jpg';
    fileName = fileName.split(' ').join('_');
    const data = await this.wasabiService.uploadMedia(file, folder, fileName);
    this.loading = false;

    console.log("storeThumbnailFile  data:",data);
    console.log("storeThumbnailFile  type:",type);
    if (data) {

      if(type==this.sharedService.instagramProvider){

        this.isInstaThumbLoading = false;
        this.instaReelThumbnails.push(folder + fileName);
        this.deleteChange.next({
          type: this.type,
          maxLength: this.maxLength,
          deletedPhotos: this.deletedPhotos,
          mediaData: this.mediaData,
          galleryMediaData: this.galleryMediaData,
          deletedGalleryMedias: this.deletedGalleryMedias,
          instaReelThumbnails: this.instaReelThumbnails,
          youtubeThumbnails: this.youtubeThumbnails
        });
        console.log("storeThumbnailFile  instaReelThumbnails:",this.instaReelThumbnails);
        console.log("storeThumbnailFile  youtubeThumbnails:",this.youtubeThumbnails);
      }else {
        this.youtubeThumbnails.push(folder + fileName);
        this.deleteChange.next({
          type: this.type,
          maxLength: this.maxLength,
          deletedPhotos: this.deletedPhotos,
          mediaData: this.mediaData,
          galleryMediaData: this.galleryMediaData,
          deletedGalleryMedias: this.deletedGalleryMedias,
          instaReelThumbnails: this.instaReelThumbnails,
          youtubeThumbnails: this.youtubeThumbnails
        });
      }
    } else {
      this.isVideoValid = false;
    }
  }

  deleteThumbnailImage(index, type=''): void {

    if(type==this.sharedService.instagramProvider){

      this.instaReelThumbnails.splice(index, 1);
      this.deleteChange.next({
        type: this.type, maxLength: this.maxLength, deletedPhotos: this.deletedPhotos,
        mediaData: this.mediaData, galleryMediaData: this.galleryMediaData,
        deletedGalleryMedias: this.deletedGalleryMedias, instaReelThumbnails: this.instaReelThumbnails
      });

    }else{

      this.youtubeThumbnails.splice(index, 1);
      this.deleteChange.next({
        type: this.type, maxLength: this.maxLength, deletedPhotos: this.deletedPhotos, mediaData: this.mediaData,
        galleryMediaData: this.galleryMediaData, deletedGalleryMedias: this.deletedGalleryMedias, youtubeThumbnails: this.youtubeThumbnails
      });

    }

  }

  tabChanged(index) {
    console.log("tabChanged")
    this.currentTab = index;
    this.selectedMedias = [];
    this.albumId = null;

    //reset selected media
    this.selectedGalleryFiles = [];
    this.selectedGalleryFileMetas = [];
  }

  //remove media from album start
  removeMediaToAlbum(mediaId) {
    this.removeAlbumMedia = mediaId;
    this.removeMediaFromAlbum = true;
  }
  cancelRemoveMideaFromAlbum() {
    this.removeMediaFromAlbum = false;
  }

  removemediaFromSelectedAlbum(mediaId) {
    console.log("remove media from album:", mediaId);
    var postValue = {
      media_id: mediaId
    }
    this.isLoading = true;
    this.userGalleryService.removeMediaFromAlbumList(postValue).subscribe(res => {
      this.isLoading = false;
      if (res?.code === 200) {
        if (res?.toast === true) {
          this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
          this.galleryFiles = [];
          this.removeMediaFromAlbum = false;
          this.galleryModalisVisible = false;
          this.selectedImage = null;
        }
        this.openGallery();
        this.getIsFavoriteGallery('deleteFavImage');
        this.getAlbumList('deleteAlbumImage');
        this.selectedImage = null;
      }
      if (res?.code === 500 && res?.toast === true) {
        this.isLoading = false;
        this.removeMediaFromAlbum = true;
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
      }
    }, error => {
      this.isLoading = false;
      this.removeMediaFromAlbum = true;
    });
  }


  handleRemove = (file: any) => new Observable<boolean>((obs) => {
    console.log(obs);

    return obs;
  })

  onChangeEventUpload = (event) => {
    console.log(event);

    if ((this.isTotalCount > this.maxLength) && (event.fileList.length == this.isTotalCount) && (event.type == 'start') && (!this.isExitingLimit)) {
      this.onGoingHttpReq.next();
      this.currentUploading = [];
      // this.images = [];
      this.sharedService.displayNotification(this.sharedService.messageTypeInfo, 'Media uploading', 'only ' + this.maxLength + ' media uploded at time.');
      // this.userPhoto.clear();
      this.isExitingLimit = true;
      // event.file = {};
      return true;
    } else {
      this.isExitingLimit = false;
    }

    var totalImage = event.fileList.length + this.images.length;
    console.log(totalImage + ' = ' + event.fileList.length + " + " + this.images.length);
    console.log(this.isExitingLimit);
    console.log(event.fileList.length);


    if ((totalImage > this.maxLength) && (!this.isExitingLimit) && (event.fileList.length == this.currentUploading.length) && (this.images.length > 0)) {
      console.log("if 3");
      this.isExitingLimit = false;
      this.currentUploading = [];
      this.onGoingHttpReq.next();
      // this.userPhoto.nativeElement.remove();
      this.sharedService.displayNotification(this.sharedService.messageTypeInfo, 'Media uploading', 'Maximum ' + this.maxLength + ' media uploded at time.');
      return false;
    }
  }

  selectMedias(event:Event,media,medias=[]){
    console.log("selectMedias medias:",medias)

    //album
    if(this.currentTab === 0) {
      this.selectedMedias = this.galleryFiles.flatMap(media =>
        media.map(mediadata => mediadata[1]
            .filter(galleryMedia => galleryMedia.is_checked)
            .map(galleryMedia => galleryMedia._id))).flat();
    }else if(this.currentTab === 1){
      //this.selectedMedias = medias.filter(item => item.is_checked).map(item => item.media_id);
      this.selectedMedias = medias.filter(item => item.media?.is_checked)
        .map(item => item.media_id);
    }else if(this.currentTab === 2){
      this.selectedMedias = medias.filter(item => item.is_checked)
        .map(item => item._id);
    }

    //for create post
    if(this.page==='create_post') {
      if(this.selectedGalleryFiles.length + this.images.length >= 10){
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, "You cannot selected more than 10 images");
        return false;
      }
      this.selectMediaFromGallery(media);
    }
    //console.log("selectMedias selectedMedias:",this.selectedMedias)

  }


  selectAlbum(event){
    console.log("selectAlbum event:",event)
    this.selectedAlbumIds = this.albumList
      .filter(album => album.is_checked)
      .map(album => album._id);
  }

  removeSelectedMediaFromAlbum() {
    var postValue = {
      media_id: this.selectedMedias,
      album_id: this.albumId,
    }
    if(this.removeAlbumMedia) {
      postValue.media_id =  this.removeAlbumMedia
    }
    console.log("this.removeAlbumMedia",this.removeAlbumMedia);
    this.isLoading = true;
    this.userGalleryService.removeMediaFromAlbumList(postValue).subscribe(res => {
        this.isLoading = false;
        if (res?.code === 200) {
          if (res?.toast === true) {
            this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
            this.galleryFiles = [];
            this.removeMediaFromAlbum = false;
            this.galleryModalisVisible = false;
            this.selectedImage = null;
          }

          /*this.openGallery();
          this.getIsFavoriteGallery('deleteFavImage');
          this.getAlbumList('deleteAlbumImage');
          this.selectedImage = null;
          this.selectedMedias = [];*/
          this.resetSelection();
        }

        if (res?.code === 500 && res?.toast === true) {
          this.isLoading = false;
          this.removeMediaFromAlbum = true;
          this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
        }
      }, error => {
        this.isLoading = false;
        this.removeMediaFromAlbum = true;
    });
  }


  /*Dropdown options*/
  addToAlbum(media_id=null,event:MouseEvent=null) {
    event?.stopPropagation();
    event?.preventDefault();
    this.addToAlbumVisible = true;
    this.albumList.forEach(item => {
      item.is_checked = false;
    });
    if(media_id){
      this.selectedSingleMedia = media_id;
    }
  }

  closeAddAlbumModal() {
    this.addToAlbumVisible = false;
    this.selectedMedias = [];
    // this.getAlbumList();

  }

  deleteMedia(id: any,event:MouseEvent=null) {
    console.log("deleteMedia id:",id)
    event?.stopPropagation();
    event?.preventDefault();

    this.deleteImagevisible = true;
    this.deleteImageFromSelected = id;
  }

  deleteImageModalClose() {
    this.deleteImagevisible = false
  }

  addToFavorite(media=null,event:MouseEvent=null,isRemove=false) {
    event?.stopPropagation();
    event?.preventDefault();

    let selectedMediaIds = this.selectedMedias;
    if(media){
      selectedMediaIds = [media._id];
      this.removeAllFav = !!media.is_favorite;
    }

    if(isRemove){
      this.removeAllFav = true;
    }

    if(selectedMediaIds.length === 0){
      this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, "Please select at least one media");
      return;
    }

    var postValue: any = {
      'selected_media_ids' : selectedMediaIds,
      'remove' : this.removeAllFav,
    };

    this.userGalleryService.addToFavorite(postValue)
      .subscribe(res => {
        if(res.code===200){
          this.removeAllFav = false;
          this.resetSelection();
          this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res?.message);
        }else{
          this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res?.message);
        }

    });

  }

  removeAllFavorite(){
    this.removeAllFav = true;
    this.addToFavorite();
  }
  /*Dropdown options*/

  resetSelection(){
    this.selectedMedias = [];
    this.selectedAlbumIds = [];
    this.selectedSingleMedia = null;
    if(this.currentTab===0){
      this.openGallery();
    }else if(this.currentTab===1){
      if(this.albumId){
        this.allImageFromAlbum(this.albumId,this.currentAlbumName);
      }else{
        this.getAlbumList();
      }
    }else if(this.currentTab===2) {
      this.getIsFavoriteGallery();
    }
  }

  handleClickOnOptions(event:MouseEvent){
    event.stopPropagation();
    event.preventDefault();
    console.log("handleClickOnOptions")
  }

  createNewAlbum(){
    this.newAlbum = true;
  }

  drop(event: any) {
    console.log("drop event",event);
    console.log("drop images",this.images);
    console.log("drop type",this.type);

    if(this.type==="multi"){
      this.images = this.multi_images.map((media) => media.media_file);
    }

    this.imagesChanged.next({images: this.images});
    console.log("drop images new",this.images);
    console.log("drop images multi_images",this.multi_images);
  }

  sortableOptions = {
    onEnd: (event: any) => this.drop(event),
    sort: true
  };

  hashtagGenerate(index, img: string):void{
    console.log("img",img);
    this.sharedService.hashtagGenerateModal.next({isShowHashTagGenerate : true, img});
  }
  GalleryImageLoading(isLoading: boolean) {
    this.isGalleryImageLoading = isLoading;
    console.log('Parent component loading status:', isLoading);
  }


}
